<!--
 * @Author: 林娴
 * @LastEditors: 林娴
-->


<script>
import iframeMixin from '../iframeMixin.vue'

export default {
  mixins: [iframeMixin],
  computed: {
    url() {
     return '/ureport/preview?_u=mysql:orderReport.ureport.xml&_i=1'
    },
  },
}
</script>
